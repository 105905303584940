const sort = {
  shank: 1,
  shank2: 2,
  shankFastener: 3,
  fastener: 4,
  burr: 5,
  hopiBurr: 6,
  rivet: 7,
  lTab: 8,
  tab: 9,
  button: 10,
  buttonSpare: 11,
  zipper: 12,
  zipper2: 13,
  zipperPull: 14,
  zipperTape: 15,
  biasTape: 16,
  canvasTape: 17,
  tape: 18,
  elastic: 19,
  piping: 20,
  mainLabel: 21,
  buckle: 22,
  sizeLabel: 23,
  cooLabel: 24,
  fitLabel: 25,
  label501Batwing: 26,
  labelPremium: 27,
  leatherLikeLabel: 28,
  flagLabel: 29,
  label: 30,
  patch: 31,
  leatherPatch: 32,
  patchVerbiage: 33,
  snap: 34,
  stud: 35,
  hookAndEye: 36,
  eyelet: 37,
  cordStopper: 38,
  drawcord: 39,
  collarStay: 40,
  hookAndLoop: 41,
  miscellaneous: 42,
  debossLogo: 43,
  embossLogo: 44,
  engraveLogo: 45,
  velcro: 46,
  magnet: 47,
  slider: 48,
  dRing: 49,
  foam: 50,
  sweatBand: 51,
  webbing: 52,
  binding: 53,
  cord: 54,
  pomPom: 55,
  fringe: 56,
  endTip: 57,
  snapback: 58,
  engraving: 59,
  other: 60,
  hook: 61,
  keeper: 62
}

const components = Object.keys(sort)

const locations = [
  'back',
  'backNeck',
  'beltLoop',
  'centerBack',
  'centerFront',
  'cfPlacket',
  'chest',
  'collar',
  'cuff',
  'fly',
  'front',
  'hangerLoop',
  'hem',
  'hiddenPocket',
  'hood',
  'hookAndEye',
  'interior',
  'internal',
  'internalWaistband',
  'leftChest',
  'leftSideSeam',
  'lockerLoop',
  'loop',
  'neck',
  'placket',
  'pocket',
  'shell',
  'shoulder',
  'side',
  'sleeve',
  'spareButton',
  'splitHem',
  'waistband'
]

export default {
  components,
  locations,
  sort
}
